const quoteStyle = {
  
  itemGrid: {
    marginLeft: "auto",
    marginRight: "auto"
  },
  section: {
    //paddingBottom: "70px",
    //textAlign: "center"
  },
};

export default quoteStyle;
